import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Margin from 'gatsby-theme-signalwerk/src/components/Margin';
import Grid from 'gatsby-theme-signalwerk/src/components/Grid';
import Column from 'gatsby-theme-signalwerk/src/components/Column';
import Box from 'gatsby-theme-signalwerk/src/components/Box';
export const _frontmatter = {
  "title": "Titel der Arbeit",
  "author": "Stefan Huber",
  "date": "2022-11-11",
  "layout": "post",
  "draft": false,
  "hideInMenu": false,
  "path": "/posts/2020/example/",
  "tags": ["TagOne", "TagTwo"],
  "description": "Lead · Einleitung zur Arbeit"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2 {...{
      "id": "intro"
    }}>{`Intro`}</h2>
    <p>{`Grundsätzlich verhält sich das Template wie normaler Markdown-Text. Es gibt aber einige erweiterte Funktionen. Für weitere Infos siehe `}<a parentName="p" {...{
        "href": "https://github.com/signalwerk/gatsby-theme-signalwerk"
      }}>{`Github`}</a>{`.`}</p>
    <h2 {...{
      "id": "inhaltsverzeichnis"
    }}>{`Inhaltsverzeichnis`}</h2>
    <div {...{
      "className": "toc"
    }}></div>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#intro"
          }}>{`Intro`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#inhaltsverzeichnis-element"
          }}>{`Inhaltsverzeichnis (Element)`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#eingabe"
            }}>{`Eingabe`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#darstellung"
            }}>{`Darstellung`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#text"
          }}>{`Text`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#marginalie"
            }}>{`Marginalie`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#listen"
          }}>{`Listen`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#eingabe-1"
            }}>{`Eingabe`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#darstellung-1"
            }}>{`Darstellung`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#nummerierte-listen"
          }}>{`Nummerierte Listen`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#eingabe-2"
            }}>{`Eingabe`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#darstellung-2"
            }}>{`Darstellung`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#code"
          }}>{`Code`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#eingabe-3"
            }}>{`Eingabe`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#darstellung-3"
            }}>{`Darstellung`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#fussnoten"
          }}>{`Fussnoten`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#eingabe-4"
            }}>{`Eingabe`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#darstellung-4"
            }}>{`Darstellung`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#abbildungsverzeichnis"
          }}>{`Abbildungsverzeichnis`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#eingabe-5"
            }}>{`Eingabe`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#darstellung-5"
            }}>{`Darstellung`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#zitate"
          }}>{`Zitate`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#eingabe-6"
            }}>{`Eingabe`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#darstellung-6"
            }}>{`Darstellung`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#bilder"
          }}>{`Bilder`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#eingabe-7"
            }}>{`Eingabe`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#darstellung-7"
            }}>{`Darstellung`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#video"
          }}>{`Video`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#eingabe-8"
            }}>{`Eingabe`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#darstellung-8"
            }}>{`Darstellung`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#grid--volle-breite"
          }}>{`Grid – volle Breite`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#eingabe-9"
            }}>{`Eingabe`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#darstellung-9"
            }}>{`Darstellung`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#grid--volle-breite-mit-hintergrund"
          }}>{`Grid – volle Breite mit Hintergrund`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#eingabe-10"
            }}>{`Eingabe`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#darstellung-10"
            }}>{`Darstellung`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#grid--mit-spalten"
          }}>{`Grid – mit Spalten`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#eingabe-11"
            }}>{`Eingabe`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#darstellung-11"
            }}>{`Darstellung`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#cat-column-a"
            }}>{`Cat Column A`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#cat-column-b"
            }}>{`Cat Column B`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#einzelnachweise"
          }}>{`Einzelnachweise`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#abbildungsverzeichnis-1"
          }}>{`Abbildungsverzeichnis`}</a></p>
      </li>
    </ul>
    <undefined></undefined>
    <h2 {...{
      "id": "inhaltsverzeichnis-element"
    }}>{`Inhaltsverzeichnis (Element)`}</h2>
    <p>{`Das Inhaltsverzeichnis kann über ein `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`toc`}</code>{` (Table of Contents) Code-Block gesteuert werden.`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`exclude`}</code>{` – welcher Titel soll nicht im Inhaltsverzeichnis erscheinen`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`from-heading`}</code>{` – erste Stufe, die ausgegeben werden soll. `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`2`}</code>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`h2`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`to-heading`}</code>{` – letzte Stufe, die ausgegeben werden soll. `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`6`}</code>{` = `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`h6`}</code></li>
    </ul>
    <h3 {...{
      "id": "eingabe"
    }}>{`Eingabe`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "md"
    }}><pre parentName="div" {...{
        "className": "language-md"
      }}><code parentName="pre" {...{
          "className": "language-md"
        }}><span parentName="code" {...{
            "className": "token code"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`\`\`\``}</span><span parentName="span" {...{
              "className": "token code-language"
            }}>{`toc`}</span>{`
`}<span parentName="span" {...{
              "className": "token code-block language-toc"
            }}>{`exclude: Inhaltsverzeichnis
from-heading: 2
to-heading: 6`}</span>{`
`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`\`\`\``}</span></span></code></pre></div>
    <h3 {...{
      "id": "darstellung"
    }}>{`Darstellung`}</h3>
    <p>{`siehe oben`}</p>
    {
      /*
      ```toc
      exclude: Inhaltsverzeichnis
      from-heading: 2
      to-heading: 6
      ``` */
    }
    <h2 {...{
      "id": "text"
    }}>{`Text`}</h2>
    <Margin mdxType="Margin">
      <h3 {...{
        "id": "marginalie"
      }}>{`Marginalie`}</h3>
      <p>{`Text Marginalie · Randspalten mit `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<Margin></Margin>`}</code>{`-Tags umschliessen.`}</p>
    </Margin>
    <p>{`Text · Normaler Text kann wie in Markdown üblich verfasst werden.`}</p>
    <h2 {...{
      "id": "listen"
    }}>{`Listen`}</h2>
    <h3 {...{
      "id": "eingabe-1"
    }}>{`Eingabe`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "md"
    }}><pre parentName="div" {...{
        "className": "language-md"
      }}><code parentName="pre" {...{
          "className": "language-md"
        }}><span parentName="code" {...{
            "className": "token list punctuation"
          }}>{`*`}</span>{` Item A
`}<span parentName="code" {...{
            "className": "token list punctuation"
          }}>{`*`}</span>{` Item B
`}<span parentName="code" {...{
            "className": "token list punctuation"
          }}>{`*`}</span>{` Item C`}</code></pre></div>
    <h3 {...{
      "id": "darstellung-1"
    }}>{`Darstellung`}</h3>
    <ul>
      <li parentName="ul">{`Item A`}</li>
      <li parentName="ul">{`Item B`}</li>
      <li parentName="ul">{`Item C`}</li>
    </ul>
    <h2 {...{
      "id": "nummerierte-listen"
    }}>{`Nummerierte Listen`}</h2>
    <h3 {...{
      "id": "eingabe-2"
    }}>{`Eingabe`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "md"
    }}><pre parentName="div" {...{
        "className": "language-md"
      }}><code parentName="pre" {...{
          "className": "language-md"
        }}><span parentName="code" {...{
            "className": "token list punctuation"
          }}>{`1.`}</span>{` Item 1
`}<span parentName="code" {...{
            "className": "token list punctuation"
          }}>{`2.`}</span>{` Item 2
`}<span parentName="code" {...{
            "className": "token list punctuation"
          }}>{`3.`}</span>{` Item 3`}</code></pre></div>
    <h3 {...{
      "id": "darstellung-2"
    }}>{`Darstellung`}</h3>
    <ol>
      <li parentName="ol">{`Item 1`}</li>
      <li parentName="ol">{`Item 2`}</li>
      <li parentName="ol">{`Item 3`}</li>
    </ol>
    <h2 {...{
      "id": "code"
    }}>{`Code`}</h2>
    <p>{`Code kann entweder als Block oder Inline eingegeben werden.`}</p>
    <h3 {...{
      "id": "eingabe-3"
    }}>{`Eingabe`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "md"
    }}><pre parentName="div" {...{
        "className": "language-md"
      }}><code parentName="pre" {...{
          "className": "language-md"
        }}>{`Inline `}<span parentName="code" {...{
            "className": "token code keyword"
          }}>{`\`code\``}</span>{`

`}<span parentName="code" {...{
            "className": "token code"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`\`\`\``}</span><span parentName="span" {...{
              "className": "token code-language"
            }}>{`js`}</span>{`
`}<span parentName="span" {...{
              "className": "token code-block language-js"
            }}><span parentName="span" {...{
                "className": "token keyword"
              }}>{`var`}</span>{` kk `}<span parentName="span" {...{
                "className": "token operator"
              }}>{`=`}</span>{` `}<span parentName="span" {...{
                "className": "token string"
              }}>{`"kk"`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`;`}</span></span>{`
`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`\`\`\``}</span></span></code></pre></div>
    <h3 {...{
      "id": "darstellung-3"
    }}>{`Darstellung`}</h3>
    <p>{`Inline `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`code`}</code></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` kk `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"kk"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h2 {...{
      "id": "fussnoten"
    }}>{`Fussnoten`}</h2>
    <p>{`Text mit Fussnoten kann wie in Markdown üblich entweder mit Referenzen gemacht werden:`}</p>
    <h3 {...{
      "id": "eingabe-4"
    }}>{`Eingabe`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "md"
    }}><pre parentName="div" {...{
        "className": "language-md"
      }}><code parentName="pre" {...{
          "className": "language-md"
        }}><span parentName="code" {...{
            "className": "token italic"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`_`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`«Zitat A»`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`_`}</span></span>{`[^quote-one]

`}<span parentName="code" {...{
            "className": "token url-reference url"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="span" {...{
              "className": "token variable"
            }}>{`^quote-one`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` ...`}</span></code></pre></div>
    <p>{`oder inline`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "md"
    }}><pre parentName="div" {...{
        "className": "language-md"
      }}><code parentName="pre" {...{
          "className": "language-md"
        }}><span parentName="code" {...{
            "className": "token italic"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`_`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`«Zitat B»`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`_`}</span></span>{`[^hier der Text]`}</code></pre></div>
    <h3 {...{
      "id": "darstellung-4"
    }}>{`Darstellung`}</h3>
    <p><em parentName="p">{`«Zitat A»`}</em><sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-1"
      }}><a parentName="sup" {...{
          "href": "#ref-1",
          "className": "footnote-inline-link"
        }}>{`1`}</a></sup>{`
`}<em parentName="p">{`«Zitat B»`}</em><sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-2"
      }}><a parentName="sup" {...{
          "href": "#ref-2",
          "className": "footnote-inline-link"
        }}>{`2`}</a></sup></p>
    <h2 {...{
      "id": "abbildungsverzeichnis"
    }}>{`Abbildungsverzeichnis`}</h2>
    <p>{`Möchte man die Abbildungen (oder weitere Verzeichnisse) ausserhalb der Fussnoten pflegen, kann man die Fussnoten mit einem Gruppen-Prefix (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`:gruppe:`}</code>{`) markieren:`}</p>
    <h3 {...{
      "id": "eingabe-5"
    }}>{`Eingabe`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "md"
    }}><pre parentName="div" {...{
        "className": "language-md"
      }}><code parentName="pre" {...{
          "className": "language-md"
        }}><span parentName="code" {...{
            "className": "token italic"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`_`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`Bild A`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`_`}</span></span>{`[^:fig:one]

`}<span parentName="code" {...{
            "className": "token url-reference url"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="span" {...{
              "className": "token variable"
            }}>{`^:fig:one`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`:`}</span>{` ...`}</span></code></pre></div>
    <p>{`oder inline`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "md"
    }}><pre parentName="div" {...{
        "className": "language-md"
      }}><code parentName="pre" {...{
          "className": "language-md"
        }}><span parentName="code" {...{
            "className": "token italic"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`_`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`Bild B`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`_`}</span></span>{`[^:fig:hier der Text]`}</code></pre></div>
    <h3 {...{
      "id": "darstellung-5"
    }}>{`Darstellung`}</h3>
    <p><em parentName="p">{`Bild A`}</em><sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-fig-1"
      }}><a parentName="sup" {...{
          "href": "#ref-fig-1",
          "className": "footnote-inline-link"
        }}>{`Fig. 1`}</a></sup>{`
`}<em parentName="p">{`Bild B`}</em><sup parentName="p" {...{
        "className": "footnote-inline",
        "id": "use-ref-fig-2"
      }}><a parentName="sup" {...{
          "href": "#ref-fig-2",
          "className": "footnote-inline-link"
        }}>{`Fig. 2`}</a></sup></p>
    <h2 {...{
      "id": "zitate"
    }}>{`Zitate`}</h2>
    <p>{`Absätze mit Zitaten können speziell markiert werden.`}</p>
    <h3 {...{
      "id": "eingabe-6"
    }}>{`Eingabe`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "md"
    }}><pre parentName="div" {...{
        "className": "language-md"
      }}><code parentName="pre" {...{
          "className": "language-md"
        }}><span parentName="code" {...{
            "className": "token blockquote punctuation"
          }}>{`>`}</span>{` Absatz als Zitat.[^quote-one]
`}</code></pre></div>
    <h3 {...{
      "id": "darstellung-6"
    }}>{`Darstellung`}</h3>
    <blockquote>
      <p parentName="blockquote">{`Absatz als Zitat.`}<sup parentName="p" {...{
          "className": "footnote-inline",
          "id": "use-ref-1"
        }}><a parentName="sup" {...{
            "href": "#ref-1",
            "className": "footnote-inline-link"
          }}>{`1`}</a></sup></p>
    </blockquote>
    <h2 {...{
      "id": "bilder"
    }}>{`Bilder`}</h2>
    <p>{`Wenn unter einem Bild direkt im Anschluss ein ausgezeichneter Text (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`*text*`}</code>{`) steht, erhält dieser Text die Formatierung für Bildunterschriften.`}</p>
    <h3 {...{
      "id": "eingabe-7"
    }}>{`Eingabe`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "md"
    }}><pre parentName="div" {...{
        "className": "language-md"
      }}><code parentName="pre" {...{
          "className": "language-md"
        }}><span parentName="code" {...{
            "className": "token url"
          }}>{`![`}<span parentName="span" {...{
              "className": "token content"
            }}>{`Cat`}</span>{`](./img/header.jpg)`}</span>{`
`}<span parentName="code" {...{
            "className": "token italic"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`*`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`Wow so miau. Much cute.[^:fig:pic-source]`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`*`}</span></span></code></pre></div>
    <h3 {...{
      "id": "darstellung-7"
    }}>{`Darstellung`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1400px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/588acb13a6132d0361a32a5ad8361678/3a5dd/header.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "57.14285714285714%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwAEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAEXbJL1ZZP/xAAbEAACAgMBAAAAAAAAAAAAAAACAwABERIxMv/aAAgBAQABBQIEaTNMoFDYu9wef//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAABEQ/9oACAECAQE/AVn/xAAeEAACAgAHAAAAAAAAAAAAAAAAAQISEBEhIjFhgf/aAAgBAQAGPwK03l0VWzzkVo6iFh//xAAaEAACAwEBAAAAAAAAAAAAAAARIQABMRCh/9oACAEBAAE/IbgTsNiOKmEizgCwuSvKmM//2gAMAwEAAgADAAAAEETf/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/ELWv/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAx/9oACAECAQE/EFai/8QAHBABAAIDAAMAAAAAAAAAAAAAAQAhETFxQVFh/9oACAEBAAE/EAx3Veq1MgdwMC9MvYf2pqL5cE1PsW5TQlODXZTnP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Cat",
            "title": "Cat",
            "src": "/static/588acb13a6132d0361a32a5ad8361678/3a5dd/header.jpg",
            "srcSet": ["/static/588acb13a6132d0361a32a5ad8361678/90185/header.jpg 450w", "/static/588acb13a6132d0361a32a5ad8361678/7288a/header.jpg 900w", "/static/588acb13a6132d0361a32a5ad8361678/3a5dd/header.jpg 1400w"],
            "sizes": "(max-width: 1400px) 100vw, 1400px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`Wow so miau. Much cute.`}<sup parentName="em" {...{
          "className": "footnote-inline",
          "id": "use-ref-fig-3"
        }}><a parentName="sup" {...{
            "href": "#ref-fig-3",
            "className": "footnote-inline-link"
          }}>{`Fig. 3`}</a></sup></em></p>
    <h2 {...{
      "id": "video"
    }}>{`Video`}</h2>
    <p>{`Videos sollten in eine responsive `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Box`}</code>{` gepackt werden. Als Ratio kann 16:9, 4:3 oder 3:2 gangegeben werden.`}</p>
    <h3 {...{
      "id": "eingabe-8"
    }}>{`Eingabe`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "md"
    }}><pre parentName="div" {...{
        "className": "language-md"
      }}><code parentName="pre" {...{
          "className": "language-md"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`Box`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`ratio`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`16:9`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`

`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`iframe`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`src`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`https://player.vimeo.com/video/169809377`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`frameborder`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`0`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`iframe`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`

`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`Box`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <h3 {...{
      "id": "darstellung-8"
    }}>{`Darstellung`}</h3>
    <Box ratio="16-9" mdxType="Box">
      <iframe src="https://player.vimeo.com/video/169809377" frameBorder="0"></iframe>
    </Box>
    <h2 {...{
      "id": "grid--volle-breite"
    }}>{`Grid – volle Breite`}</h2>
    <h3 {...{
      "id": "eingabe-9"
    }}>{`Eingabe`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "md"
    }}><pre parentName="div" {...{
        "className": "language-md"
      }}><code parentName="pre" {...{
          "className": "language-md"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`Grid`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`

`}<span parentName="code" {...{
            "className": "token url"
          }}>{`![`}<span parentName="span" {...{
              "className": "token content"
            }}>{`Cat`}</span>{`](./img/header.jpg)`}</span>{`
`}<span parentName="code" {...{
            "className": "token italic"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`*`}</span><span parentName="span" {...{
              "className": "token content"
            }}>{`Hello Cat[^:fig:pic-source]`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`*`}</span></span>{`

`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`Grid`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <h3 {...{
      "id": "darstellung-9"
    }}>{`Darstellung`}</h3>
    <Grid mdxType="Grid">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1400px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/588acb13a6132d0361a32a5ad8361678/3a5dd/header.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "57.14285714285714%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwAEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAEXbJL1ZZP/xAAbEAACAgMBAAAAAAAAAAAAAAACAwABERIxMv/aAAgBAQABBQIEaTNMoFDYu9wef//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAABEQ/9oACAECAQE/AVn/xAAeEAACAgAHAAAAAAAAAAAAAAAAAQISEBEhIjFhgf/aAAgBAQAGPwK03l0VWzzkVo6iFh//xAAaEAACAwEBAAAAAAAAAAAAAAARIQABMRCh/9oACAEBAAE/IbgTsNiOKmEizgCwuSvKmM//2gAMAwEAAgADAAAAEETf/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/ELWv/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAx/9oACAECAQE/EFai/8QAHBABAAIDAAMAAAAAAAAAAAAAAQAhETFxQVFh/9oACAEBAAE/EAx3Veq1MgdwMC9MvYf2pqL5cE1PsW5TQlODXZTnP//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Cat",
              "title": "Cat",
              "src": "/static/588acb13a6132d0361a32a5ad8361678/3a5dd/header.jpg",
              "srcSet": ["/static/588acb13a6132d0361a32a5ad8361678/90185/header.jpg 450w", "/static/588acb13a6132d0361a32a5ad8361678/7288a/header.jpg 900w", "/static/588acb13a6132d0361a32a5ad8361678/3a5dd/header.jpg 1400w"],
              "sizes": "(max-width: 1400px) 100vw, 1400px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`Hello Cat`}<sup parentName="em" {...{
            "className": "footnote-inline",
            "id": "use-ref-fig-3"
          }}><a parentName="sup" {...{
              "href": "#ref-fig-3",
              "className": "footnote-inline-link"
            }}>{`Fig. 3`}</a></sup></em></p>
    </Grid>
    <h2 {...{
      "id": "grid--volle-breite-mit-hintergrund"
    }}>{`Grid – volle Breite mit Hintergrund`}</h2>
    <h3 {...{
      "id": "eingabe-10"
    }}>{`Eingabe`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "language-html"
      }}><code parentName="pre" {...{
          "className": "language-html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`Grid`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`background`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`

![Cat](./img/header.jpg)
*Hello Cat[^:fig:pic-source]*

`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`Grid`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <h3 {...{
      "id": "darstellung-10"
    }}>{`Darstellung`}</h3>
    <Grid background mdxType="Grid">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1400px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/588acb13a6132d0361a32a5ad8361678/3a5dd/header.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "57.14285714285714%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwAEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAEXbJL1ZZP/xAAbEAACAgMBAAAAAAAAAAAAAAACAwABERIxMv/aAAgBAQABBQIEaTNMoFDYu9wef//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAABEQ/9oACAECAQE/AVn/xAAeEAACAgAHAAAAAAAAAAAAAAAAAQISEBEhIjFhgf/aAAgBAQAGPwK03l0VWzzkVo6iFh//xAAaEAACAwEBAAAAAAAAAAAAAAARIQABMRCh/9oACAEBAAE/IbgTsNiOKmEizgCwuSvKmM//2gAMAwEAAgADAAAAEETf/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/ELWv/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAx/9oACAECAQE/EFai/8QAHBABAAIDAAMAAAAAAAAAAAAAAQAhETFxQVFh/9oACAEBAAE/EAx3Veq1MgdwMC9MvYf2pqL5cE1PsW5TQlODXZTnP//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Cat",
              "title": "Cat",
              "src": "/static/588acb13a6132d0361a32a5ad8361678/3a5dd/header.jpg",
              "srcSet": ["/static/588acb13a6132d0361a32a5ad8361678/90185/header.jpg 450w", "/static/588acb13a6132d0361a32a5ad8361678/7288a/header.jpg 900w", "/static/588acb13a6132d0361a32a5ad8361678/3a5dd/header.jpg 1400w"],
              "sizes": "(max-width: 1400px) 100vw, 1400px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`Hello Cat`}<sup parentName="em" {...{
            "className": "footnote-inline",
            "id": "use-ref-fig-3"
          }}><a parentName="sup" {...{
              "href": "#ref-fig-3",
              "className": "footnote-inline-link"
            }}>{`Fig. 3`}</a></sup></em></p>
    </Grid>
    <h2 {...{
      "id": "grid--mit-spalten"
    }}>{`Grid – mit Spalten`}</h2>
    <h3 {...{
      "id": "eingabe-11"
    }}>{`Eingabe`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "md"
    }}><pre parentName="div" {...{
        "className": "language-md"
      }}><code parentName="pre" {...{
          "className": "language-md"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`Grid`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`Column`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`start`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`1`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`end`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`7`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`###`}</span>{` Cat Column A`}</span>{`
...

`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`Column`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`Column`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`start`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`7`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`end`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`13`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`

`}<span parentName="code" {...{
            "className": "token title important"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`###`}</span>{` Cat Column B`}</span>{`
...

`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`Column`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`Grid`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <h3 {...{
      "id": "darstellung-11"
    }}>{`Darstellung`}</h3>
    <Grid mdxType="Grid">
      <Column start="1" end="7" mdxType="Column">
        <h3 {...{
          "id": "cat-column-a"
        }}>{`Cat Column A`}</h3>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1400px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/588acb13a6132d0361a32a5ad8361678/3a5dd/header.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": ["noopener"]
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "57.14285714285714%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwAEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAEXbJL1ZZP/xAAbEAACAgMBAAAAAAAAAAAAAAACAwABERIxMv/aAAgBAQABBQIEaTNMoFDYu9wef//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAABEQ/9oACAECAQE/AVn/xAAeEAACAgAHAAAAAAAAAAAAAAAAAQISEBEhIjFhgf/aAAgBAQAGPwK03l0VWzzkVo6iFh//xAAaEAACAwEBAAAAAAAAAAAAAAARIQABMRCh/9oACAEBAAE/IbgTsNiOKmEizgCwuSvKmM//2gAMAwEAAgADAAAAEETf/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/ELWv/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAx/9oACAECAQE/EFai/8QAHBABAAIDAAMAAAAAAAAAAAAAAQAhETFxQVFh/9oACAEBAAE/EAx3Veq1MgdwMC9MvYf2pqL5cE1PsW5TQlODXZTnP//Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Cat",
                "title": "Cat",
                "src": "/static/588acb13a6132d0361a32a5ad8361678/3a5dd/header.jpg",
                "srcSet": ["/static/588acb13a6132d0361a32a5ad8361678/90185/header.jpg 450w", "/static/588acb13a6132d0361a32a5ad8361678/7288a/header.jpg 900w", "/static/588acb13a6132d0361a32a5ad8361678/3a5dd/header.jpg 1400w"],
                "sizes": "(max-width: 1400px) 100vw, 1400px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`Hello Cat`}<sup parentName="em" {...{
              "className": "footnote-inline",
              "id": "use-ref-fig-3"
            }}><a parentName="sup" {...{
                "href": "#ref-fig-3",
                "className": "footnote-inline-link"
              }}>{`Fig. 3`}</a></sup></em></p>
      </Column>
      <Column start="7" end="13" mdxType="Column">
        <h3 {...{
          "id": "cat-column-b"
        }}>{`Cat Column B`}</h3>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1400px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/588acb13a6132d0361a32a5ad8361678/3a5dd/header.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": ["noopener"]
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "57.14285714285714%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwAEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAEXbJL1ZZP/xAAbEAACAgMBAAAAAAAAAAAAAAACAwABERIxMv/aAAgBAQABBQIEaTNMoFDYu9wef//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAABEQ/9oACAECAQE/AVn/xAAeEAACAgAHAAAAAAAAAAAAAAAAAQISEBEhIjFhgf/aAAgBAQAGPwK03l0VWzzkVo6iFh//xAAaEAACAwEBAAAAAAAAAAAAAAARIQABMRCh/9oACAEBAAE/IbgTsNiOKmEizgCwuSvKmM//2gAMAwEAAgADAAAAEETf/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/ELWv/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAx/9oACAECAQE/EFai/8QAHBABAAIDAAMAAAAAAAAAAAAAAQAhETFxQVFh/9oACAEBAAE/EAx3Veq1MgdwMC9MvYf2pqL5cE1PsW5TQlODXZTnP//Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Cat",
                "title": "Cat",
                "src": "/static/588acb13a6132d0361a32a5ad8361678/3a5dd/header.jpg",
                "srcSet": ["/static/588acb13a6132d0361a32a5ad8361678/90185/header.jpg 450w", "/static/588acb13a6132d0361a32a5ad8361678/7288a/header.jpg 900w", "/static/588acb13a6132d0361a32a5ad8361678/3a5dd/header.jpg 1400w"],
                "sizes": "(max-width: 1400px) 100vw, 1400px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy"
              }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`Hello Cat`}<sup parentName="em" {...{
              "className": "footnote-inline",
              "id": "use-ref-fig-3"
            }}><a parentName="sup" {...{
                "href": "#ref-fig-3",
                "className": "footnote-inline-link"
              }}>{`Fig. 3`}</a></sup></em></p>
      </Column>
    </Grid>
    {
      /* references text*/
    }
    {
      /*
      Vorname Nachname, [Titel der Seite](https://www.google.com/) (Abrufdatum: dd. mm. yyyy).
      Vorname Nachname, Buchtitel, Auflage, Verlag, Erscheinungsort Jahr, erste Seite – letzte Seite.
      */
    }
    {
      /* references figures*/
    }
    <h2 {...{
      "id": "einzelnachweise"
    }}>{`Einzelnachweise`}</h2>
    <div {...{
      "className": "ref-notes refnotes--default"
    }}></div>
    <ul>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-1"
          }}><a parentName="span" {...{
              "id": "use-ref-1",
              "href": "#use-ref-1",
              "className": "footnote-ref-link"
            }}>{`↑ 1.`}</a>{` `}</span>{`Beispiel für Fussnote`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-2"
          }}><a parentName="span" {...{
              "id": "use-ref-2",
              "href": "#use-ref-2",
              "className": "footnote-ref-link"
            }}>{`↑ 2.`}</a>{` `}</span>{`hier der Text`}</p>
      </li>
    </ul>
    <undefined></undefined>
    <h2 {...{
      "id": "abbildungsverzeichnis-1"
    }}>{`Abbildungsverzeichnis`}</h2>
    <p>{`(Kann auch bei Einzelnachweis gepflegt werden)`}</p>
    <div {...{
      "className": "ref-notes refnotes--fig"
    }}></div>
    <ul>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-fig-1"
          }}><a parentName="span" {...{
              "id": "use-ref-fig-1",
              "href": "#use-ref-fig-1",
              "className": "footnote-ref-link"
            }}>{`↑ Fig. 1`}</a>{` – `}</span>{`Beispiel für Abbildungsverzeichnis.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-fig-2"
          }}><a parentName="span" {...{
              "id": "use-ref-fig-2",
              "href": "#use-ref-fig-2",
              "className": "footnote-ref-link"
            }}>{`↑ Fig. 2`}</a>{` – `}</span>{`hier der Text`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><span parentName="p" {...{
            "className": "footnote-ref",
            "id": "ref-fig-3"
          }}><a parentName="span" {...{
              "id": "use-ref-fig-3",
              "href": "#use-ref-fig-3",
              "className": "footnote-ref-link"
            }}>{`↑ Fig. 3`}</a>{` – `}</span>{`Abbildung von `}<a parentName="p" {...{
            "href": "http://lorempixel.com/800/600/cats/1"
          }}>{`lorempixel.com`}</a></p>
      </li>
    </ul>
    <undefined></undefined>
    <Grid mdxType="Grid">
      <hr></hr>
      <Column start="1" end="5" mdxType="Column">
        <h4 {...{
          "id": "mentoring-durch"
        }}>{`Mentoring durch`}</h4>
        <p>{`Vorname Nachname `}<br />{`
HFIAD 2017 `}<br />{`
Schule für Gestaltung Zürich `}<br />{`
`}<a parentName="p" {...{
            "href": "mailto:name@xyz.ch"
          }}>{`name@xyz.ch`}</a></p>
      </Column>
      <Column start="5" end="13" mdxType="Column">
        <h4 {...{
          "id": "vorgelegt-von"
        }}>{`Vorgelegt von`}</h4>
        <p>{`Vorname Nachname `}<br />{`
sonstiges `}<br />{`
`}<a parentName="p" {...{
            "href": "mailto:name@xyz.ch"
          }}>{`name@xyz.ch`}</a></p>
      </Column>
    </Grid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      